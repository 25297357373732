import { styled } from '@mui/material/styles';
import {useState, useEffect} from 'react';
import { format } from 'date-fns'

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import {css} from "@mui/system";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

const PopinModal = styled(Dialog)(({ theme }) => (css`
    .MuiDialog-paperScrollPaper {
      max-width: 400px;
    }
  `),
);

const ModalHeader = styled('div')(({ theme }) => (css`
    background: ${theme.palette.primary.main};
    position: relative;
    color: #fff;
    padding: 10px 20px;
    button {
      position: absolute;
      top: 0px;
      right: 0px;
      color: #a3baea;
    }
    .header-title{
      font-weight: bold;
    }
  `),
);

const ModalContent = styled('div')(({ theme }) => (css`
    padding: 24px 12px 10px;
    .vendor-fee-container{
      padding: 0 5px;
    }
    .button-group{
      text-align: right;
      margin-top: 10px
    }
  `),
);

const CustomButton = styled(Button)(({ theme }) => (css`
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  `),
);
export default function AttorneyConfirmationModal ({ open, closeModal, onUpdate, vendor, vendor_fee, due_date, setVendorFee }) {
  const [ dueDate, setDueDate] = useState(due_date)

  useEffect(() => {
    // if (vendor_fee) setVendorFee(vendor_fee)
    if (due_date)
      setDueDate(due_date)
    else
      setDueDate(getFormatDate())
  }, [])

  const getFormatDate = (date=null) => {
    if (!date)
      date = new Date()

    return format(date, 'MM/dd/yyyy')
  }

  const changeDueDate = (newValue) => {
    if (newValue) {
      var date = new Date(newValue)
      if (date < new Date()) return
      setDueDate(getFormatDate(date))
    } else {
      setDueDate("")
    }
  }

  return (
    <PopinModal open={open}>
      <ModalHeader>
        <Typography component="h6" className='header-title'>
          Vendor Confirmation
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{ color: (theme) => theme.palette.grey[500] }}
        ><CloseIcon /></IconButton>
      </ModalHeader>
      <ModalContent>
        <Grid container>
          <Grid item xs={6} className="vendor-fee-container">
            <FormControl>
              <InputLabel htmlFor="outlined-adornment-amount">VENDOR FEE</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                type="number"
                value={vendor_fee}
                onChange={(event) => setVendorFee(event.target.value)}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="VENDOR FEE"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} className="vendor-fee-container">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={dueDate}
                label="DUE DATE"
                inputFormat="MM/dd/yyyy"
                onChange={changeDueDate}
                renderInput={(params) => <TextField {...params}/>}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} className="button-group">
            <CustomButton variant="outlined" size="small" onClick={closeModal}>Cancel</CustomButton>
            <CustomButton variant="contained" size="small" onClick={() => onUpdate(dueDate)}>Update</CustomButton>
          </Grid>
        </Grid>
      </ModalContent>
    </PopinModal>
  );
};