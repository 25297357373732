import {useState, useEffect} from 'react';
import { format } from 'date-fns'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import { css } from "@mui/system";
import usePipeline from "common/pipelines/usePipeline";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CampaignIcon from '@mui/icons-material/Campaign';
import { formatPhoneNumber } from 'common/utils';
import Vendor from 'common/models/vendor'
import Order from 'common/models/order'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AttorneyConfirmationModal from './AttorneyConfirmationModal';

const TableContainerStyled = styled(TableContainer)(css`
  .MuiTableCell-head .MuiSvgIcon-root {
    color: #5a5a5b
  }
  min-height: 300px;
`);

const TableBodyStyled = styled(TableBody)(css`
  th {
    padding-left: 16px !important;
  }
`);
const TableCellStyled = styled(TableCell)(css`
  border: 0px !important;
  padding: 6px 2px;
  font-size: 14px;

  .MuiStep-root {
    margin-top: 16px;
  }
  .MuiStepLabel-label {
    font-size: 10px;
    padding-top: 4px;
    margin-top: 4px !important;
  }
`);

const OrderDescLabel = styled(Typography)(css`
  padding-top: 10px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
`);

const OrderDescriptions = styled(Typography)(css`
  font-size: 12px;
`);

const OrderDetailsBox = styled(Box)(css`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`);

const OrderLabel = styled(Typography)(css`
  padding: 10px 0;
  text-transform: uppercase;
  color: #005fd4;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  width: 50%;
  min-width: 220px;
`);

const OrderContent = styled(Typography)(css`
  display: inline-block;
`);

export default function AttorneyAssignmentPage ({ orderid, partid, closeModal }) {
  const [ data, setData ] = useState([]);
  const [ vendorFee, setVendorFee ] = useState([]);
  const [ vendorIndex, setVendorIndex] = useState(0)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarType, setSnackbarType] = useState('success')
  const [orderDetails, setOrderDetails] = useState({})
  const [alertContent, setAlertConent] = useState('')

  const vendor = (new Vendor);

  useEffect(async() => {
    let result = await vendor.getAvailabeVendors(orderid);
    setData(result.vendors);

    const orderDetails = {}
    orderDetails['vendor_min_fee'] = null
    orderDetails['vendor_max_fee'] = null

    for (let v of result.vendors) {
      if (v.average_vendor_fee) {
        if (!orderDetails.vendor_min_fee || orderDetails.vendor_min_fee > v.average_vendor_fee) orderDetails.vendor_min_fee = v.average_vendor_fee
        if (!orderDetails.vendor_max_fee || orderDetails.vendor_max_fee < v.average_vendor_fee) orderDetails.vendor_max_fee = v.average_vendor_fee
      }
    }

    const order = (new Order);
    result = await order.getDetails(orderid);
    console.log('result', result.order);
    if (result.order) {
      orderDetails['due_date'] = result.order.due_date;
      orderDetails['escrow_close'] = result.order.escrow_close;
      orderDetails['order_type_id'] = result.order.order_type_id;
    }

    result = await order.getParts(orderid);
    if (result.parts) {
      for (let p of result.parts) {
        if (p.partid == partid) {
          orderDetails['description'] = p.part_description
          break
        }
      }
    }

    setOrderDetails(orderDetails)
  },[]);

  const onAssign = async (idx) => {
    setVendorIndex(idx)
    setShowConfirmationModal(true)
    // console.log('data', data[idx]);
    // console.log('orderDetails', orderDetails);

    try {
      const vendor = new Vendor();
      const response = await vendor.getOrderFeeSchedules(data[idx]?.userid, orderDetails?.order_type_id);
      console.log('getOrderFeeSchedules', response);
      setVendorFee(response?.vendorDefaultFee.fee)
    } catch (e) {
     // handleAlert(e, 'info')
    } finally {}
  }

  const onUpdate = async (dueDate) => {
    console.log(vendorFee, dueDate)
    const result = await vendor.assignVendor(data[vendorIndex].userid, partid, vendorFee, dueDate);
    if (result.status == 'success') {
      // setAlertConent('Successfully Assigned')
      // setSnackbarType('success')
      closeModal()
    } else {
      setShowSnackbar(true)
      setAlertConent('Assign Failure')
      setSnackbarType('error')
    }
  }

  const formatDate = (date) => {
    let fdate = ""
    try {
      fdate = format(new Date(date), 'MM/dd/yyyy')
    } catch (e) { }
    return fdate
  }

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (<>
    <Typography variant="h4" component="h4">
      Details
    </Typography>
    <Grid container>
      <Grid item xs={6}>
        <OrderDescLabel>
          Order Description
        </OrderDescLabel>
        <OrderDescriptions>
          {orderDetails.description}
        </OrderDescriptions>
      </Grid>
      <Grid item xs={1}/>
      <Grid item xs={5}>
        <OrderDetailsBox>
          <OrderLabel>Escrow Close Date</OrderLabel>
          <OrderContent>{orderDetails.escrow_close && formatDate(orderDetails.escrow_close)}</OrderContent>
        </OrderDetailsBox>
        <OrderDetailsBox>
          <OrderLabel>Document Due Date</OrderLabel>
          <OrderContent>{orderDetails.due_date && formatDate(orderDetails.due_date)}</OrderContent>
        </OrderDetailsBox>
        <OrderDetailsBox>
          <OrderLabel>Anticipated Vendor Fee</OrderLabel>
          <OrderContent>
            {orderDetails.vendor_min_fee ?
              <Box component="span" m="{1}">${orderDetails.vendor_min_fee} - ${orderDetails.vendor_max_fee}</Box>
              :
              <Box component="span" m="{1}">N/A</Box>
            }
          </OrderContent>
        </OrderDetailsBox>
      </Grid>
    </Grid>
    <Typography variant="h4" component="h4" style={{ paddingTop: "32px" }}>
      Available Vendors
    </Typography>
    <TableContainerStyled>
      <Table sx={{ minWidth: 650 }} aria-label="">
        <TableHead>
          <TableRow>
            <TableCell>Full Name</TableCell>
            <TableCell align="center">Firm Name</TableCell>
            <TableCell align="center">Phone</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center"><CampaignIcon/></TableCell>
            <TableCell align="center">Avg Fee</TableCell>
            <TableCell align="center">% On Time</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBodyStyled>
          {data && data.map((row, idx) => (
            <TableRow
              key={row.userid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellStyled component="th" scope="row">
                {row.firstname} {row.lastname}
              </TableCellStyled>
              <TableCellStyled align="center">{row.company_name}</TableCellStyled>
              <TableCellStyled align="center">{row.phone && formatPhoneNumber(row.phone)}</TableCellStyled>
              <TableCellStyled align="center" color="primary"><Link href="#">{row.email}</Link></TableCellStyled>
              <TableCellStyled align="center">{row.messages_sent}</TableCellStyled>
              <TableCellStyled align="center">{row.average_vendor_fee}</TableCellStyled>
              <TableCellStyled align="center">{row.percent_on_time}</TableCellStyled>
              <TableCellStyled align="center"><Button variant="outlined" onClick={() => onAssign(idx)}>Assign</Button></TableCellStyled>
            </TableRow>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainerStyled>
    <AttorneyConfirmationModal open={showConfirmationModal} closeModal={closeConfirmationModal} onUpdate={onUpdate} vendor={data[vendorIndex]} vendor_fee={vendorFee} setVendorFee={setVendorFee} due_date={orderDetails.due_date}/>
    <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={closeSnackbar}>
      <Alert onClose={closeSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
        {alertContent}
      </Alert>
    </Snackbar>
  </>);
}

